import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/background 1.svg"
import Cube from "../images/Cube.svg"
import Card from "../images/CARD@3x.png"
import Chip from "../images/Chip.svg"
import Apple from "../images/Apple.svg"
import Playstore from "../images/Playstore.svg"
import Coin from "../images/Group 90 Copy.svg"
import Tete from "../images/Tete Logo.svg"
import "./header.css"

const SmallHeader = () => (
  <header className="small__header__container">
    <img className="header__image" src={Logo} alt="logo" />

    <div className="header__block">
      <div className="navbar2">
        <Link className="nav__links" to="/">
          <img className="tete__logo" src={Tete} alt="tete"></img>
        </Link>
        {/* <div class="hamburger-menu">
          <input id="menu__toggle" type="checkbox" />
          <label class="menu__btn" for="menu__toggle">
            <span></span>
          </label>

          <ul class="menu__box">
            <li>
              <Link className="menu__item" to="/">
                How to play
              </Link>
            </li>
            <li>
              <Link className="menu__item" to="/aboutus">
                Games
              </Link>
            </li>
            <li>
              <Link className="menu__item" to="/aboutus">
                FAQ
              </Link>
            </li>
            <li>
              <Link className="menu__item" to="/privacy">
                Privacy
              </Link>
            </li>
            <li>
              <Link className="menu__item" to="/terms">
                Terms and conditions
              </Link>
            </li>
          </ul>
        </div> */}
        {/* <div className="nav__content">
          <Link className="nav__links" to="#how">
            How to play
          </Link>
          <Link className="nav__links" to="#games">
            Games
          </Link>
          <Link className="nav__links" to="#faq">
            FAQ
          </Link>
        </div> */}
      </div>
      <div className="header__set2">
        <div className="header__content">
          <div className="header__textwrapper">
            <p className="header__text2">Privacy Policy</p>
            <p className="header__text3">Updated: July 20, 2020</p>
            {/* <h2 style={{color:"#b2b8e5", textAlign: "center"}}>Updated:  July 25th, 2020</h2> */}
          </div>
        </div>
      </div>
    </div>
  </header>
)

export default SmallHeader
