import React from "react"
import { Link } from "gatsby"
import Footer from "../components/footer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SmallHeader from "../components/smallheader"
import "../components/font.css"

const Privacy = () => (
  <div>
    <SEO title="Privacy"/>
    <SmallHeader />
    <div
      style={{
        paddingTop: "50px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        lineHeight: "150%",
        fontFamily: "font-family: pro;",
      }}
    >
      <div style={{
        width: "84%",
        padding: "9px",
      }}>
      <p style={{fontSize: "17px", opacity: "0.6"}}>
        This service is provided by tete.ng ltd at no cost and is intended for
        use as is.
      </p>
      <p style={{fontSize: "17px", opacity: "0.6"}}>
        This page is used to inform visitors regarding our policies with the
        collection, use, and disclosure of Personal Information if anyone
        decided to use our Service.
      </p>
      <p style={{fontSize: "17px", opacity: "0.6"}}>
        If you choose to use our Service, then you agree to the collection and
        use of information in relation to this policy. The Personal Information
        that we collect is used for providing and improving the Service. We will
        not use or share your information with anyone except as described in
        this Privacy Policy.
      </p>

      <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>
        Information Collection and Use
      </div>
      <p style={{fontSize: "17px", opacity: "0.6"}}>
        For a better experience, while using our Service, we may require you to
        provide us with certain personally identifiable information. The
        information that we request will be retained by us and used as described
        in this privacy policy.
      </p>
      <p style={{fontSize: "17px", opacity: "0.6"}}>
        The app does use third party services that may collect information used
        to identify you. Link to privacy policy of third party service providers
        used by the app
      </p>

      <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>Cookies</div>
      <p style={{fontSize: "17px", opacity: "0.6"}}>
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.
      </p>
      <p style={{fontSize: "17px", opacity: "0.6"}}>
        This Service does not use these “cookies” explicitly. However, the app
        may use third party code and libraries that use “cookies” to collect
        information and improve their services. You have the option to either
        accept or refuse these cookies and know when a cookie is being sent to
        your device. If you choose to refuse our cookies, you may not be able to
        use some portions of this Service.
      </p>

      <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>
        Service Providers
      </div>
      <p style={{fontSize: "17px", opacity: "0.6"}}>
        We may employ third-party companies and individuals due to the following
        reasons:
      </p>

      <ul style={{opacity: "0.6"}}>
        <li>To facilitate our Service.</li>
        <li>To provide the Service on our behalf.</li>
        <li>To perform Service-related services or</li>
        <li>To assist us in analyzing how our Service is used.</li>
        <li>
          We want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </li>
      </ul>

      <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>Security</div>
      <p style={{fontSize: "17px", opacity: "0.6"}}>
        We value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security.
      </p>

      <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>
        Links to Other Sites
      </div>
      <p style={{fontSize: "17px", opacity: "0.6"}}>
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>

      <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>
        Children’s Privacy
      </div>
      <p style={{fontSize: "17px", opacity: "0.6"}}>
        These Services do not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from children
        under 13. In the case we discover that a child under 13 has provided us
        with personal information, we immediately delete this from our servers.
        If you are a parent or guardian and you are aware that your child has
        provided us with personal information, please contact us so that we will
        be able to do necessary actions.
      </p>

      <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>
        Changes to This Privacy Policy
      </div>
      <p style={{fontSize: "17px", opacity: "0.6"}}>
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page. These
        changes are effective immediately after they are posted on this page.
      </p>
      <div style={{marginTop: 40, marginBottom: 10, fontSize: 23, fontWeight: 600 }}>Contact Us</div>
      <p style={{fontSize: "17px", opacity: "0.6"}}>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us at admin@tete.ng
      </p>
      </div>
    </div>
    <Footer/>
  </div>
)

export default Privacy
